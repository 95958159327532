/* eslint-disable no-prototype-builtins */
import { defineComponent, getCurrentInstance, h, computed, ref, watchEffect } from 'vue'

import useField, { useFieldState, useFieldProps, useFieldEmits } from 'quasar/src/composables/private/use-field.js'
import { useFormProps, useFormInputNameAttr } from 'quasar/src/composables/private/use-form.js'
import { QIcon } from 'quasar/src/components/icon'
import { QBtn } from 'quasar/src/components/btn'
import { QDate } from 'quasar/src/components/date'
import { QTime } from 'quasar/src/components/time'
import { XPopupProxy } from '../popup-proxy'

const dateProps = {
  // Behavior
  yearsInMonthView: Boolean,
  // Content
  title: String,
  subtitle: String,
  todayBtn: Boolean,
  minimal: Boolean,
  // Model
  defaultYearMonth: String,
  defaultView: String,
  events: [Array, Function],
  firstDayOfWeek: [String, Number],
  multiple: Boolean,
  range: Boolean,
  emitImmediately: Boolean,
  // Selection
  navigationMinYearMonth: String,
  navigationMaxYearMonth: String,
  noUnset: Boolean,
  // Style
  eventColor: [String, Function]
}

const timeProps = {
  // Behavior
  format24h: Boolean,
  hourOptions: Array,
  minuteOptions: Array,
  secondOptions: Array,
  withSeconds: Boolean,
  nowBtn: Boolean,
  // Model
  defaultDate: String
}

const commonProps = {
  landscape: Boolean,
  locale: Object,
  calendar: String,
  // Style
  color: String,
  textColor: String,
  dark: Boolean,
  square: Boolean,
  flat: Boolean,
  bordered: Boolean
}

function useProps (props, targetProps) {
  const result = {}
  for (const i in props) {
    if (targetProps[i]) {
      result[i] = props[i]
    }
  }
  return result
}

export default defineComponent({
  name: 'XDatetime',

  inheritAttrs: false,

  props: {
    ...useFieldProps,
    ...useFormProps,

    modelValue: { required: false },
    type: String,
    mask: String,

    ...dateProps,
    ...timeProps,
    ...commonProps,

    dateOptions: [Array, Function],
    timeOptions: [Function],

    inputClass: [Array, String, Object],
    inputStyle: [Array, String, Object]
  },

  emits: [
    ...useFieldEmits,
    'change'
  ],

  setup (props, { emit, attrs }) {
    const vm = getCurrentInstance()
    const state = useFieldState()
    const fieldClass = computed(() => 'x-datetime')
    const inputRef = ref(null)
    const nameProp = useFormInputNameAttr(props)

    const dateValue = ref(props.modelValue)
    const timeValue = ref(props.modelValue)

    const datePopup = ref(false)
    const timePopup = ref(false)

    watchEffect(() => {
      dateValue.value = props.modelValue
      timeValue.value = props.modelValue
    })

    const inputAttrs = computed(() => {
      return {
        tabindex: 0,
        'data-autofocus': props.autofocus === true || undefined,
        'aria-label': props.label,
        name: nameProp.value,
        ...state.splitAttrs.attributes.value,
        id: state.targetUid.value,
        disabled: props.disable === true,
        readonly: true
      }
    })

    const dateAttrs = computed(() => {
      return {
        ...useProps(props, dateProps),
        ...useProps(props, commonProps),
        options: props.dateOptions
      }
    })

    const timeAttrs = computed(() => {
      return {
        ...useProps(props, timeProps),
        ...useProps(props, commonProps),
        options: props.timeOptions
      }
    })

    const type = computed(() => {
      if (props.type === 'date') {
        return 'date'
      }
      if (props.type === 'time') {
        return 'time'
      }
      return 'datetime'
    })

    const mask = computed(() => {
      if (props.mask) {
        return props.mask
      }
      if (type.value === 'date') {
        return 'YYYY-MM-DD'
      }
      if (type.value === 'time') {
        return 'HH:mm:ss'
      }
      return 'YYYY-MM-DD HH:mm:ss'
    })

    const icon = computed(() => {
      if (type.value === 'date') {
        return 'event'
      }
      if (type.value === 'time') {
        return 'access_alarm'
      }
      return 'access_time'
    })

    function renderControl () {
      return h('input', {
        ref: inputRef,
        class: [
          'q-field__native q-placeholder cursor-pointer',
          props.inputClass
        ],
        style: props.inputStyle,
        ...inputAttrs.value,
        value: props.modelValue
      })
    }

    function renderInnerAppend () {
      return h(QIcon, { name: icon.value }, rednerPopup)
    }

    function rednerPopup () {
      if (type.value === 'date') {
        return renderDatePopup()
      }
      if (type.value === 'time') {
        return renderTimePopup()
      }
      return [renderDatePopup(), renderTimePopup()]
    }

    function renderDatePopup () {
      return h(XPopupProxy, {
        modelValue: datePopup.value,
        transitionShow: 'scale',
        transitionHide: 'scale',
        noParentEvent: true,
        noRefocus: true,
        'onUpdate:modelValue': (v) => { datePopup.value = v },
        onBeforeHide: onFocusout
      }, renderDate)
    }

    function renderTimePopup () {
      return h(XPopupProxy, {
        modelValue: timePopup.value,
        transitionShow: 'scale',
        transitionHide: 'scale',
        noParentEvent: true,
        noRefocus: true,
        'onUpdate:modelValue': (v) => { timePopup.value = v },
        onBeforeHide: onFocusout
      }, renderTime)
    }

    function renderDate () {
      return h(QDate, {
        mask: mask.value,
        modelValue: dateValue.value,
        ...dateAttrs.value,
        'onUpdate:modelValue': (v) => { dateValue.value = v }
      }, () => renderPickerAction(renderDateBtns()))
    }

    function renderTime () {
      return h(QTime, {
        mask: mask.value,
        modelValue: timeValue.value,
        ...timeAttrs.value,
        'onUpdate:modelValue': (v) => { timeValue.value = v }
      }, () => renderPickerAction(renderTimeBtns()))
    }

    function renderPickerAction (control) {
      return h('div', {
        class: 'row items-center justify-end'
      }, control)
    }

    function renderDateBtns () {
      const btns = [renderCloseBtn()]
      if (type.value === 'datetime' && dateValue.value) {
        btns.push(renderTimeBtn())
      } else if (dateValue.value) {
        btns.push(renderSureBtn(() => {
          datePopup.value = false
          state.emitValue(dateValue.value)
        }))
      }
      return btns
    }

    function renderTimeBtns () {
      const btns = [renderCloseBtn()]
      if (timeValue.value) {
        btns.push(renderSureBtn(() => {
          timePopup.value = false
          state.emitValue(timeValue.value)
        }))
      }
      return btns
    }

    function renderTimeBtn () {
      return h(QBtn, {
        label: '选择时间',
        icon: 'access_time',
        color: 'primary',
        flat: true,
        onClick: () => {
          timeValue.value = dateValue.value
          datePopup.value = false
          timePopup.value = true
        }
      })
    }

    function renderCloseBtn () {
      return h(QBtn, {
        label: '关闭',
        color: 'orange-8',
        flat: true,
        onClick: () => {
          timePopup.value = false
          datePopup.value = false
        }
      })
    }

    function renderSureBtn (callback) {
      return h(QBtn, {
        label: '确定',
        color: 'primary',
        flat: true,
        onClick: callback
      })
    }

    function onFocusin (e) {
      state.onControlFocusin(e)
    }

    function onFocusout (e) {
      if (!datePopup.value && !timePopup.value) {
        state.onControlFocusout(e)
      }
    }

    function onClick () {
      if (props.readonly || props.disable) {
        return
      }
      dateValue.value = props.modelValue
      timeValue.value = props.modelValue
      if (type.value === 'time') {
        timePopup.value = true
      } else {
        datePopup.value = true
      }
    }

    Object.assign(state, {
      fieldClass,
      inputRef,
      getControl: renderControl,
      getInnerAppend: renderInnerAppend,
      controlEvents: {
        onFocusin,
        onFocusout,
        onClick
      }
    })

    const renderFn = useField(state)

    // expose public methods
    Object.assign(vm.proxy, {})

    return renderFn
  }
})
